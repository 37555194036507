<template>
  <div :class="`page ${currentDirectory.slug}`">
    <ais-configure :distinct="true" />

    <SearchHero />
    <div class="columns is-multiline is-desktop">
      <FeaturedPrimaryBanner />
      <Ad class="column is-12 mt-5" ad-slot="SearchBannerAd" />
      <Search class="column" @highlight="highlight" @dehighlight="dehighlight" />
      <ais-state-results
        v-if="hasMap"
        id="mapsContainer"
        :class-names="{ 'ais-StateResults': 'column is-7 is-inline-block' }"
      >
        <template slot-scope="{ results: { hits } }">
          <DirectoryMap ref="directorymap" :markers="hits" />
        </template>
      </ais-state-results>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { icon, point } from 'leaflet';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import Search from '@/components/Search.vue';
import SearchHero from '@/components/Global/SearchHero.vue';
import DirectoryMap from '@/components/DirectoryMap.vue';
import FeaturedPrimaryBanner from '@/components/InstantSearch/FeaturedPrimaryBanner.vue';
import Ad from '@/components/Ads/Ad.vue';

export default {
  computed: {
    ...mapState({
      enableMap: (state) => state.map.enabled,
      markers: (state) => state.maps.markers,
      instanceName: (state) => state.name,
    }),
    ...mapGetters({
      currentDirectory: 'directory/current',
    }),
    primaryFilter() {
      return this
        .$router
        .currentRoute.query
        ?.[this.currentDirectory.primary_filter.field]
        ?.replace('~', ', ');
    },
    hasMap() {
      return this.enableMap && this.currentDirectory.view !== 'grid';
    },
  },
  methods: {
    resetPage() {
      window.scrollTo(0, 0);
      // Remove all highlighted elements
      const allResults = document.querySelectorAll('.ais-hits a.media');
      allResults.forEach((result) => result.classList.remove('has-background-secondary-lighter'));
    },
    highlight(id) {
      if (this.hasMap === false) {
        return;
      }

      const iconToSet = icon({
        iconUrl: '/images/marker-icon-red.png',
        tooltipAnchor: point([17, -20]),
      });

      this.$refs.directorymap.$refs.map.mapObject.eachLayer((layer) => {
        if (layer.options.id === id) {
          layer.setIcon(iconToSet);
          layer.openTooltip(layer.getLatLng());
          layer.setZIndexOffset(1000);
        }
      });
    },
    dehighlight(id) {
      if (this.hasMap === false) {
        return;
      }

      const iconToSet = icon({
        iconUrl,
        tooltipAnchor: point([17, -20]),
      });

      this.$refs.directorymap.$refs.map.mapObject.eachLayer((layer) => {
        if (layer.options.id === id) {
          layer.setIcon(iconToSet);
          layer.closeTooltip();
          layer.setZIndexOffset(900);
        }
      });
    },
  },
  mounted() {
    this.$store.dispatch('advertisements/get');
  },
  components: {
    Search,
    SearchHero,
    DirectoryMap,
    FeaturedPrimaryBanner,
    Ad,
  },
  metaInfo() {
    const title = [
      this.currentDirectory.name,
      this.primaryFilter,
      this.instanceName,
    ].filter(Boolean).join(' - ');
    const meta = [
      {
        name: 'description',
        content: this.currentDirectory.meta_description,
      },
    ];
    return { title, meta };
  },
};
</script>
