<template>
  <div v-if="addRecordText">
    <div class="media-content is-flex has-text-centered">
      <div class="m-auto p-2">
        <h4 class="title is-size-4 mb-0 is-underlined">
          <a target="_blank" rel="noopener" :href="addRecordLink">
            {{ addRecordText }}
          </a>
        </h4>
        <p>
          Click here to submit your data to the directory!
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapState({
      clientName: (state) => state.name,
    }),
    ...mapGetters({
      currentDirectory: 'directory/current',
    }),
    addRecordText() {
      return this.currentDirectory.add_record_text ?? null;
    },
    addRecordLink() {
      return this.currentDirectory.add_record_link ?? `mailto:corrections@datajoe.com?subject=${this.clientName} New Listing | ${this.currentDirectory.name} | ${this.recordName}`;
    },
  },
};
</script>
